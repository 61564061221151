import * as React from 'react';
import { IconButton, Box, AppBar, useMediaQuery, Toolbar, styled, Stack, Theme } from '@mui/material';
import { useSelector, useDispatch } from '../../../../store/Store';
import { toggleMobileSidebar } from '../../../../store/customizer/CustomizerSlice';
import { IconMenu2 } from '@tabler/icons-react';
import Notifications from '../../../../layouts/full/vertical/header/Notification';
import Cart from '../../../../layouts/full/vertical/header/Cart';
import Profile from '../../../../layouts/full/vertical/header/Profile';
import Search from '../../../../layouts/full/vertical/header/Search';
import Language from '../../../../layouts/full/vertical/header/Language';
import Navigation from '../../../../layouts/full/vertical/header/Navigation';
import Logo from '../../../../layouts/full/shared/logo/Logo';
import { AppState } from '../../../../store/Store';
const Header = () => {
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  // drawer
  const customizer = useSelector((state: AppState) => state.customizer);
  const dispatch = useDispatch();
  const AppBarStyled = styled(AppBar)(({
    theme
  }) => ({
    background: theme.palette.background.paper,
    justifyContent: 'center',
    backdropFilter: 'blur(4px)',
    [theme.breakpoints.up('lg')]: {
      minHeight: customizer.TopbarHeight
    }
  }));
  const ToolbarStyled = styled(Toolbar)(({
    theme
  }) => ({
    margin: '0 auto',
    width: '100%',
    color: `${theme.palette.text.secondary} !important`
  }));
  return <AppBarStyled position="sticky" color="default" elevation={8} data-sentry-element="AppBarStyled" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <ToolbarStyled sx={{
      maxWidth: customizer.isLayout === 'boxed' ? 'lg' : '100%!important'
    }} data-sentry-element="ToolbarStyled" data-sentry-source-file="Header.tsx">
        <Box sx={{
        width: lgDown ? '45px' : 'auto',
        overflow: 'hidden'
      }} data-sentry-element="Box" data-sentry-source-file="Header.tsx">
          <Logo data-sentry-element="Logo" data-sentry-source-file="Header.tsx" />
        </Box>
        {/* ------------------------------------------- */}
        {/* Toggle Button Sidebar */}
        {/* ------------------------------------------- */}
        {lgDown ? <IconButton color="inherit" aria-label="menu" onClick={() => dispatch(toggleMobileSidebar())}>
            <IconMenu2 />
          </IconButton> : ''}
        {/* ------------------------------------------- */}
        {/* Search Dropdown */}
        {/* ------------------------------------------- */}
        <Search data-sentry-element="Search" data-sentry-source-file="Header.tsx" />
         {lgUp ? <>
            <Navigation />
          </> : null}
        <Box flexGrow={1} data-sentry-element="Box" data-sentry-source-file="Header.tsx" />
        <Stack spacing={1} direction="row" alignItems="center" data-sentry-element="Stack" data-sentry-source-file="Header.tsx">
          {/* ------------------------------------------- */}
          {/* Ecommerce Dropdown */}
          {/* ------------------------------------------- */}
          <Cart data-sentry-element="Cart" data-sentry-source-file="Header.tsx" />
          {/* ------------------------------------------- */}
          {/* End Ecommerce Dropdown */}
          {/* ------------------------------------------- */}
          <Notifications data-sentry-element="Notifications" data-sentry-source-file="Header.tsx" />
          <Profile data-sentry-element="Profile" data-sentry-source-file="Header.tsx" />
        </Stack>
      </ToolbarStyled>
    </AppBarStyled>;
};
export default Header;