// ** React Imports
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Abi } from 'viem';
import { zora } from 'viem/chains';
import { CollectorClient, createCollectorClient } from '@zoralabs/protocol-sdk';
import { usePublicClient, useWriteContract } from 'wagmi';

/**
 * Zora interface
 */
interface Zora {
  mint: (tokenContract: `0x${string}`, tokenAbi: Abi, tokenId: string, mintToAddress: `0x${string}`, quantityToMint: number | bigint, minterAccount: `0x${string}`) => Promise<void>;
}
const ZoraContext = createContext<Zora>({} as Zora);

/**
 * Zora context provider
 */
export function ZoraProvider({
  children
}: {
  children: ReactNode;
}) {
  const [client, setClient] = useState<CollectorClient | null>(null);
  const {
    writeContractAsync
  } = useWriteContract();
  const publicClient = usePublicClient();
  useEffect(() => {
    if (publicClient) {
      configZoraClient();
    }
  }, [publicClient]);
  const configZoraClient = () => {
    try {
      if (!publicClient) return;
      const collectorClient = createCollectorClient({
        chainId: zora.id,
        publicClient
      });
      setClient(collectorClient);
    } catch (error) {
      console.log(`Error initializing zora client: ${error}`);
    }
  };
  const mint = async (tokenContract: `0x${string}`, tokenAbi: Abi, tokenId: string, mintToAddress: `0x${string}`, quantityToMint: number | bigint, minterAccount: `0x${string}`) => {
    if (!client) return;
    try {
      // prepare a transaction to mint an 1155 token
      const {
        parameters
      } = await client.mint({
        mintType: '1155',
        // 1155 contract address
        tokenContract,
        // id of the token to mint
        tokenId,
        // address that will receive the minted tokens
        mintRecipient: mintToAddress,
        // quantity of tokens to mint
        quantityToMint,
        // account to execute the mint transaction
        minterAccount
      });
      const tx = await writeContractAsync(parameters);
      console.log(`Transaction sent: ${tx}`);
    } catch (error) {
      console.log(`Error minting token ${error}`);
    }
  };
  const value: Zora = {
    mint
  };
  return <ZoraContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="ZoraProvider" data-sentry-source-file="zora.tsx">{children}</ZoraContext.Provider>;
}
export function useZora() {
  return useContext(ZoraContext);
}