// ** Next Imports
import { useRouter } from 'next/router';

// ** MUI Imports
import { Typography, useTheme, BottomNavigation, BottomNavigationAction, useMediaQuery, Box, Card } from '@mui/material';
import MenuItems from '../sidebar/MenuItems';
import { useMemo } from 'react';
const mobileLabelMap = {
  Feed: 'Feed',
  People: 'People',
  Content: 'Trending',
  Collect: 'Collections',
  'Orb clubs': 'Communities'
};
const menuList = MenuItems.filter(item => mobileLabelMap[item.title as keyof typeof mobileLabelMap]);
const mobileMenuList = menuList.map(item => ({
  ...item,
  mobileLabel: mobileLabelMap[item.title as keyof typeof mobileLabelMap]
}));
const getPageByHref = (href: string) => {
  const findItem = (items: typeof mobileMenuList): (typeof mobileMenuList)[0] | null => {
    for (const item of items) {
      if (!item.href) continue;
      if (item.href === href) return item;
      if (item.children) {
        const foundChild = findItem(item.children as any[]);
        if (foundChild) return foundChild;
      }
    }
    return null;
  };
  return findItem(mobileMenuList) || -1;
};
const MobileNavigation = () => {
  const router = useRouter();
  const {
    pathname
  } = router;
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const currentPage = useMemo(() => getPageByHref(pathname), [pathname]);
  if (!isSmScreen) return null;
  return <Card variant="outlined" sx={{
    padding: 0,
    borderBottom: `3px solid ${theme.palette.grey[100]}`,
    borderRadius: 0,
    borderLeft: 0,
    borderRight: 0
  }} data-sentry-element="Card" data-sentry-component="MobileNavigation" data-sentry-source-file="index.tsx">
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} sx={{
      backgroundColor: theme.palette.grey[100]
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <BottomNavigation showLabels value={pathname} onChange={(event, newValue) => {
        router.push(newValue);
      }} sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: theme.palette.grey[100]
      }} data-sentry-element="BottomNavigation" data-sentry-source-file="index.tsx">
          {mobileMenuList.map(el => {
          const Icon = el.icon;
          return <BottomNavigationAction key={el.id} value={el.href} icon={<Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '90%',
            paddingTop: '0.5em',
            borderTop: currentPage !== -1 && 'href' in currentPage ? currentPage.href === el.href ? `3px solid ${theme.palette.primary.main}` : 'none' : 'none'
          }}>
                    <Icon size={25} color={currentPage !== -1 && currentPage?.href === el.href ? theme.palette.primary.main : theme.palette.text.secondary} />
                  </Box>} sx={{
            padding: 0,
            minWidth: '50px'
          }} />;
        })}
        </BottomNavigation>
        {currentPage ? <Typography variant="caption" textAlign={'center'} fontWeight={'700'} letterSpacing={1}>
            {currentPage !== -1 && currentPage.mobileLabel}
          </Typography> : null}
      </Box>
    </Card>;
};
export default MobileNavigation;