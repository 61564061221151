// ** React Imports
import { ReactNode, createContext, useContext, useState, useEffect } from 'react';

// ** Hooks
import { useLens } from './lens';
import axios from 'axios';

// ** Utils
import { BACKEND_URL } from '../utils/constants/api';
import { GroupBy } from '../utils/constants/types';
import { FeedItem, TrendingCollectsType } from '@/types/custom';
import { UserEngagementType } from '@/types/apps/userProfile';
export type BackendValue = {
  apps: any[];
  languages: any[];
  contentTypes: any[];
  interests: any[];
  searchedUsers: any[];
  trendingUsersCache: any[];
  searchUsers: (appIds: string[], interests: string[], activities: number[], groupBy: GroupBy) => Promise<any>;
  fetchLatestPost: (profileId: string) => Promise<any>;
  findInfluencers: (profileIds: string[]) => Promise<any>;
  fetchUsersTrendings: (ratio: string, interest: string, page?: number, limit?: number) => Promise<any>;
  fetchContentTrendings: (ratio: string, type: string) => Promise<TrendingCollectsType[] | undefined>;
  fetchTrendingApps: () => Promise<any>;
  fetchTrendingContentType: () => Promise<any>;
  fetchCollectsContentType: () => Promise<any>;
  fetchOrbClubsTrending: (page: number, limit?: number, orderBy?: string) => Promise<any>;
  fetchOrbClubsActivities: () => Promise<any>;
  fetchOrbClubActivities: (clubId: string) => Promise<any>;
  fetchOrbClubMetadata: (clubId: string) => Promise<any>;
  fetchFollowersHistory: (profileId: string) => Promise<any>;
  fetchTopFollowers: (profileId: string) => Promise<any>;
  fetchFollowersDistribution: () => Promise<any>;
  fetchActiveUsers: () => Promise<any>;
  fetchTrendingCollectApps: () => Promise<any>;
  fetchTrendingAppsPerContentType: () => Promise<any>;
  getFeedPage: (forProfileId?: string, cursor?: string, limit?: number) => Promise<{
    items: Array<FeedItem>;
    nextCursor?: string;
  } | undefined>;
  fetchUserLatestsFollowers: (profileId: string) => Promise<any>;
  fetchUserEngagement: (profileId: string) => Promise<UserEngagementType | undefined>;
};
const BackendContext = createContext<BackendValue>({} as BackendValue);
export function BackendProvider({
  children
}: {
  children: ReactNode;
}) {
  const {
    getProfilesById
  } = useLens();
  const [apps, setApps] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [interests, setInterests] = useState([]);
  const [trendingUsersCache, setTrendingUsersCache] = useState<any>();
  const [searchedUsers, setSearchedUsers] = useState([]);
  useEffect(() => {
    fetchApps();
    fetchLanguages();
    fetchContentTypes();
    fetchInterests();
  }, []);
  const fetchApps = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/post/apps`);
      setApps(res.data);
    } catch (error) {
      console.log('Error fetching apps:', error);
    }
  };
  const fetchLanguages = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/post/languages`);
      setLanguages(res.data);
    } catch (error) {
      console.log('Error fetching languages:', error);
    }
  };
  const fetchContentTypes = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/post/contentTypes`);
      setContentTypes(res.data);
    } catch (error) {
      console.log('Error fetching content types:', error);
    }
  };
  const fetchInterests = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userInterest/interests`);
      setInterests(res.data);
    } catch (error) {
      console.log('Error fetching interests:', error);
    }
  };
  const searchUsers = async (appIds: string[], interests: string[], activities: number[], groupBy: GroupBy) => {
    try {
      if (searchedUsers.length) setSearchedUsers([]);
      const res = await axios.post(`${BACKEND_URL}/user/find`, {
        appIds,
        interests,
        activities,
        groupBy
      });
      setSearchedUsers(res.data.profileIds);
      return res.data;
    } catch (error) {
      console.log('Error fetching users:', error);
    }
  };
  const findInfluencers = async (profileIds: string[]) => {
    try {
      const res = await axios.post(`${BACKEND_URL}/user-profile-follower/influencers`, {
        profileIds
      });
      return res.data;
    } catch (error) {
      console.log('Error fetching users influencers:', error);
    }
  };
  const fetchLatestPost = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/post/latest/${profileId}`);
      return res.data[0];
    } catch (error) {
      console.log('Error fetching latest post:', error);
    }
  };
  const fetchUsersTrendings = async (ratio: string, interest: string, page?: number, limit = 50) => {
    const actualPage = page ? page : 1;
    if (trendingUsersCache && trendingUsersCache.ratio === ratio && trendingUsersCache.interest === interest && !page) {
      return {
        usersWithRatio: trendingUsersCache.data,
        pagination: trendingUsersCache.pagination
      };
    }
    try {
      const res = await axios.post(`${BACKEND_URL}/userTrending/find`, {
        ratio,
        interest,
        page: actualPage,
        limit
      });
      const {
        users,
        page: resPage,
        total,
        totalPages
      } = res.data;
      if (!users || !users.length) {
        throw new Error('No users found.');
      }
      const usersIds = users.map((user: any) => user.profileId);
      const usersMetadata = await getProfilesById(usersIds);
      const usersWithRatio = usersMetadata.items.map((user: any) => {
        const userData = users.find((u: any) => u.profileId === user.id);
        const userRatio = userData ? userData.ratioCount : 0;
        const topFollowers = userData ? userData.topFollowers.slice(0, 5) : [];
        return {
          ...user,
          ratio: userRatio,
          topFollowers
        };
      });
      setTrendingUsersCache((prevCache: any) => ({
        usersWithRatio: actualPage === 1 ? usersWithRatio : [...prevCache.usersWithRatio, ...usersWithRatio],
        pagination: {
          page: resPage,
          total,
          totalPages
        },
        ratio,
        interest
      }));
      return {
        pagination: {
          page: resPage,
          total,
          totalPages
        }
      };
    } catch (error) {
      console.log('Error fetching trending users:', error);
    }
  };
  const fetchContentTrendings = async (ratio: string, type: string) => {
    try {
      const res = await axios.post(`${BACKEND_URL}/contentTrending/find`, {
        ratio,
        type
      });
      return res.data as TrendingCollectsType[];
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchTrendingApps = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/appTrending/trending`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchTrendingAppsPerContentType = async () => {
    try {
      const contentTypes = {
        Text: ['TEXT_ONLY'],
        Audio: ['AUDIO'],
        Video: ['VIDEO', 'SHORT_VIDEO', 'LIVESTREAM'],
        Image: ['IMAGE'],
        Article: ['ARTICLE']
      };
      const result = {
        Text: [],
        Audio: [],
        Video: [],
        Image: [],
        Article: []
      };
      const res = await axios.get(`${BACKEND_URL}/appTrending/contentTypeApps`, {});
      res.data.forEach(item => {
        for (const [key, types] of Object.entries(contentTypes)) {
          if (types.includes(item.type)) {
            const existingApp = result[key].find(app => app.identifier === item.identifier);
            if (existingApp) {
              existingApp.count += item.ratioCount;
            } else {
              result[key].push({
                identifier: item.identifier,
                count: item.ratioCount
              });
            }
          }
        }
      });
      for (const key in result) {
        result[key].sort((a, b) => b.count - a.count);
        result[key] = result[key].slice(0, 3);
      }
      return result;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchTrendingCollectApps = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/appTrending/collectsApps`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchActiveUsers = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/appTrending/activeUsers`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchTrendingContentType = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/contentTypeTrending/trending`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchCollectsContentType = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/contentTypeTrending/collects`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchOrbClubsTrending = async (page: number, limit: number = 50, orderBy?: string) => {
    try {
      const query = new URLSearchParams();
      query.append('page', page.toString());
      query.append('limit', limit.toString());
      if (orderBy) {
        query.append('orderBy', orderBy);
      }
      const base = `${BACKEND_URL}/orbclubstrending/find`;
      const queryStringify = query.toString();
      const endpoint = queryStringify ? `${base}?${queryStringify}` : base;
      const res = await axios.post(endpoint);
      return res.data;
    } catch (error) {
      console.log('Error fetching orb clubs trendings:', error);
    }
  };
  const fetchOrbClubMetadata = async (clubId: string) => {
    try {
      const query = new URLSearchParams();
      query.append('clubId', clubId.toString());
      const base = `${BACKEND_URL}/orbclubstrending/club-data`;
      const endpoint = `${base}?${query}`;
      const res = await axios.post(endpoint);
      return res.data;
    } catch (error) {
      console.log('Error fetching orb club metadata:', error);
    }
  };
  const fetchOrbClubsActivities = async () => {
    try {
      const base = `${BACKEND_URL}/orbclubstrending/clubs-activity`;
      const res = await axios.get(base);
      return res.data;
    } catch (error) {
      console.log('Error fetching orb clubs activities:', error);
    }
  };
  const fetchOrbClubActivities = async (clubId: string) => {
    try {
      const query = new URLSearchParams();
      query.append('clubId', clubId.toString());
      const base = `${BACKEND_URL}/orbclubstrending/club-activity`;
      const endpoint = `${base}?${query}`;
      const res = await axios.post(endpoint);
      return res.data;
    } catch (error) {
      console.log('Error fetching orb club activities:', error);
    }
  };
  const fetchTopFollowers = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userFollowers/topFollowers/${profileId}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching top followers:', error);
    }
  };
  const fetchFollowersHistory = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userFollowers/followersHistory/${profileId}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching followers history:', error);
    }
  };
  const fetchFollowersDistribution = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userFollowers/histogram/`);
      return res.data;
    } catch (error) {
      console.log('Error fetching top followers:', error);
    }
  };
  const getFeedPage = async (forProfileId?: string, cursor?: string, limit: number = 10) => {
    try {
      const params = new URLSearchParams();
      if (cursor) params.append('cursor', cursor);
      if (limit !== 50) params.append('limit', limit.toString());
      if (forProfileId) params.append('forProfileId', forProfileId);
      const url = `${BACKEND_URL}/feed${params.toString() ? `?${params.toString()}` : ''}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log('Error fetching feed page:', error);
    }
  };
  const fetchUserLatestsFollowers = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userFollowers/recentFollowers/${profileId}`);
      return res.data.followers;
    } catch (error) {
      console.log('Error fetching user latest followers:', error);
    }
  };
  const fetchUserEngagement = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/user/profile/engagement/${profileId}`);
      return res.data as UserEngagementType;
    } catch (error) {
      console.log('Error fetching user engagement:', error);
    }
  };
  const value: BackendValue = {
    apps,
    searchUsers,
    languages,
    contentTypes,
    fetchLatestPost,
    interests,
    searchedUsers,
    findInfluencers,
    fetchUsersTrendings,
    trendingUsersCache,
    fetchContentTrendings,
    fetchTrendingApps,
    fetchTrendingContentType,
    fetchCollectsContentType,
    fetchOrbClubsTrending,
    fetchOrbClubsActivities,
    fetchOrbClubActivities,
    fetchOrbClubMetadata,
    fetchFollowersHistory,
    fetchTopFollowers,
    fetchFollowersDistribution,
    fetchActiveUsers,
    fetchTrendingCollectApps,
    fetchTrendingAppsPerContentType,
    getFeedPage,
    fetchUserLatestsFollowers,
    fetchUserEngagement
  };
  return <BackendContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="BackendProvider" data-sentry-source-file="backend.tsx">{children}</BackendContext.Provider>;
}
export function useBackend() {
  return useContext(BackendContext);
}