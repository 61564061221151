// ** React & MUI
import { useEffect, useState } from 'react';
import { Modal, Button, Stack, CircularProgress, Box, Typography, Divider, useTheme } from '@mui/material';

// ** Components
import BaseModal from './base';

// ** Context
import { useModalsActions, useModalsState } from '@/context/modals';
import { useLens } from '@/context/lens';
import { PostFragment, LegacySimpleCollectModuleSettingsFragment } from '@lens-protocol/client';
import { useSession } from '@/context/session';

/**
 * Payload for the Collect modal
 * @param publicationId - The publicationId of the publication to be collected.
 */
export interface CollectPayload {
  publicationId: string;
  onSuccess: () => void;
}

/////////////////////////////////////
export const CollectModal = () => {
  const theme = useTheme();
  const {
    user
  } = useSession();
  const {
    close
  } = useModalsActions();
  const {
    collect: collectModal
  } = useModalsState();
  const {
    fetchCollectCriteria: getPostCollectCriteria,
    collect
  } = useLens();
  const [postId, setPostId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [post, setPost] = useState<PostFragment>();
  const [canCollect, setCanCollect] = useState<boolean>();
  const [isCollected, setIsCollected] = useState<boolean>();
  const [isFollowing, setIsFollowing] = useState<boolean>();
  const [collectSettings, setCollectSettings] = useState<LegacySimpleCollectModuleSettingsFragment>();

  /*************************************************
   *                    Render                      *
   *************************************************/

  useEffect(() => {
    setIsLoading(true);
    if (collectModal.data?.publicationId) {
      const {
        publicationId
      } = collectModal.data;
      setPostId(publicationId);
      getCollectCriteria(publicationId);
    }
  }, [collectModal.data?.publicationId, collectModal.isOpen]);

  /*************************************************
   *                  Handlers                     *
   *************************************************/

  const getCollectCriteria = async (publicationId: string) => {
    try {
      const collectCriteria = await getPostCollectCriteria(publicationId);
      if (!collectCriteria) throw new Error('No collect criteria found.');
      setPost(collectCriteria.post);
      setIsFollowing(collectCriteria.post.by.operations.isFollowedByMe.value);
      setCollectSettings(collectCriteria.settings as any);
      if (collectCriteria.canCollect === 'YES') {
        setCanCollect(true);
      } else {
        setCanCollect(false);
      }
      setIsCollected(collectCriteria.post.operations.hasCollected.value);
      setIsLoading(false);
    } catch (error) {
      console.log('Error while running collect criteria function: ', error);
    }
  };

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const handleCloseModal = () => {
    close('collect');
  };
  const handleConfirmClick = async () => {
    setSubmitting(true);
    await handleSubmit();
  };
  const handleSubmit = async () => {
    try {
      if (!postId) throw new Error('No publication id provided');
      const lensRes = await collect(postId);
      if (collectModal.data?.onSuccess && lensRes?.isSuccess) collectModal.data.onSuccess();
      handleCloseModal();
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };
  return <Modal open={collectModal.isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-component="CollectModal" data-sentry-source-file="collect.tsx">
      <BaseModal sx={{
      justifyContent: 'center'
    }} data-sentry-element="BaseModal" data-sentry-source-file="collect.tsx">
        {isLoading ? <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
            <CircularProgress />
          </Box> : <Stack spacing={4}>
            {canCollect ? <Stack gap={0.4}>
                <Typography variant="h5" textAlign="center">
                  Collect publication
                </Typography>
                <Divider sx={{
            mt: '0.5em'
          }} />
                <Stack sx={{
            mt: '1em'
          }} direction="row" gap={0.5}>
                  <Typography variant="subtitle2" sx={{
              fontWeight: 'bold'
            }}>
                    Post by:
                  </Typography>
                  <Typography variant="subtitle2" color={theme.palette.primary.dark}>
                    {post?.by?.handle?.localName}
                  </Typography>
                </Stack>
                {collectSettings?.amount.value !== '0' && <>
                    <Stack direction="row" gap={0.5}>
                      <Typography variant="subtitle2" sx={{
                fontWeight: 'bold'
              }}>
                        Collect ammount:
                      </Typography>
                      <Typography variant="subtitle2">
                        {`${collectSettings?.amount.value} ${collectSettings?.amount.asset.name}`}
                      </Typography>
                    </Stack>
                    {collectSettings?.amount?.rate?.value && <Stack direction="row" gap={0.5}>
                        <Typography variant="subtitle2" sx={{
                fontWeight: 'bold'
              }}>
                          Collect fee ammount:
                        </Typography>
                        <Typography variant="subtitle2">
                          {`${collectSettings?.amount?.rate?.value} ${collectSettings?.amount?.rate?.asset.name}`}
                        </Typography>
                      </Stack>}
                  </>}
                {collectSettings?.followerOnly && !isFollowing && <Box sx={{
            mt: '1em'
          }}>
                    <Typography variant="caption" color={theme.palette.error.dark}>
                      You need to follow the publication owner to succesfully
                      collect this post
                    </Typography>
                  </Box>}
              </Stack> : <Box>
                <Typography textAlign="center">
                  You can no longer collect this publication :(
                </Typography>
              </Box>}
            <Stack display="flex" flexDirection="row" justifyContent="center" gap={2}>
              <Button color="success" variant='contained' size="large" disabled={submitting || !canCollect || isCollected} onClick={handleConfirmClick}>
                {submitting ? <CircularProgress /> : isCollected ? 'Collected' : 'Collect'}
              </Button>
              <Button color="error" size="large" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Stack>
          </Stack>}
      </BaseModal>
    </Modal>;
};